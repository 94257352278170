"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.COLORS = exports.queryClient = void 0;
var react_query_1 = require("@tanstack/react-query");
exports.queryClient = new react_query_1.QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: function (failureCount, error) {
                if (failureCount > 1) {
                    return false;
                }
                if (!error.response)
                    return false;
                if (error.response && error.response.status === 401)
                    return true;
                else
                    return false;
            },
            staleTime: 1000 * 60 * 60 * 24,
            networkMode: 'always',
            retryDelay: 1000
        },
        mutations: {
            retry: function (failureCount, error) {
                if (failureCount > 1) {
                    return false;
                }
                if (!error.response)
                    return false;
                if (error.response.status === 401)
                    return true;
                else
                    return false;
            },
            retryDelay: 1000,
            networkMode: 'always'
        }
    }
});
exports.COLORS = {
    base: {
        white: '#FFFFFF',
        black: '#1B1B22'
    },
    primary: {
        50: '#fde8ea',
        100: '#fcdde0',
        200: '#f9b8bf',
        300: '#ed1b2f',
        400: '#d5182a',
        500: '#F24B56'
    },
    secondary: {
        error: '#fd4f4f',
        warning: '#eec32e',
        success: '#4fd584',
        information: '#3399ff'
    },
    neutral: {
        50: '#F7F7F7',
        100: '#ebebec',
        200: '#d5d6d8',
        300: '#797c80',
        400: '#6d7073',
        500: '#616366',
        600: '#5b5d60',
        700: '#494a4d',
        800: '#36383a',
        900: '#2a2b2d'
    }
};
