"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@import/mui/material");
var theme = (0, material_1.createThemeMui)({
    palette: {
        primary: {
            50: '#fde8ea',
            100: '#fcđe0',
            200: '#f9b8bf',
            300: 'ed1n2f',
            400: '#d5182a',
            500: '#F24B56',
            600: '#b21423',
            700: '#E6E8EC'
        },
        common: {
            white: '#FFFFFF',
            black: '#1B1B22',
        }
    },
    typography: {
        fontFamily: 'SaraBunRegular',
        fontSize: 14,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        fontWeightBold: 700,
        h1: {
            fontFamily: 'SaraBunSemiBold'
        },
        h3: {
            fontFamily: 'SaraBunBold'
        }
    }
});
exports.default = theme;
