"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var ErrorMessageForm = function (_a) {
    var touched = _a.touched, error = _a.error;
    if (!touched || typeof error !== 'string') {
        return null;
    }
    return ((0, jsx_runtime_1.jsx)("div", { style: { color: 'red', margin: '5px 0 0 15px', fontSize: '11px' }, children: error }));
};
exports.default = ErrorMessageForm;
