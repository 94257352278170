// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/public/fonts/Sarabun-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("/public/fonts/Sarabun-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("/public/fonts/Sarabun-Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("/public/fonts/Sarabun-SemiBold.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*,
body,
html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: SaraBunRegular;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: SaraBunMedium;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: SaraBunBold;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: SaraBunSemiBold;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_3___});
  font-weight: 700;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  color: black !important;
  -webkit-text-fill-color: black !important;
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
  -webkit-background-clip: text !important;
  background-clip: text !important;
}

.leaflet-container {
  height: 1000px;
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/global.css"],"names":[],"mappings":"AAAA;;;EAGE,SAAS;EACT,UAAU;EACV,sBAAsB;AACxB;;AAEA;EACE,2BAA2B;EAC3B,4CAA6C;EAC7C,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,0BAA0B;EAC1B,4CAA0C;EAC1C,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,wBAAwB;EACxB,4CAAwC;EACxC,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,4BAA4B;EAC5B,4CAA4C;EAC5C,gBAAgB;AAClB;;AAEA;;;;EAIE,uBAAuB;EACvB,yCAAyC;EACzC,uDAAuD;EACvD,wCAAwC;EACxC,gCAAgC;AAClC;;AAEA;EACE,cAAc;EACd,WAAW;AACb","sourcesContent":["*,\nbody,\nhtml {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n}\n\n@font-face {\n  font-family: SaraBunRegular;\n  src: url('/public/fonts/Sarabun-Regular.ttf');\n  font-weight: normal;\n  font-style: normal;\n}\n\n@font-face {\n  font-family: SaraBunMedium;\n  src: url(/public/fonts/Sarabun-Medium.ttf);\n  font-weight: normal;\n  font-style: normal;\n}\n\n@font-face {\n  font-family: SaraBunBold;\n  src: url(/public/fonts/Sarabun-Bold.ttf);\n  font-weight: bold;\n  font-style: normal;\n}\n\n@font-face {\n  font-family: SaraBunSemiBold;\n  src: url(/public/fonts/Sarabun-SemiBold.ttf);\n  font-weight: 700;\n}\n\ninput:-webkit-autofill,\ninput:-webkit-autofill:hover,\ninput:-webkit-autofill:focus,\ninput:-webkit-autofill:active {\n  color: black !important;\n  -webkit-text-fill-color: black !important;\n  -webkit-box-shadow: 0 0 0 1000px white inset !important;\n  -webkit-background-clip: text !important;\n  background-clip: text !important;\n}\n\n.leaflet-container {\n  height: 1000px;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
